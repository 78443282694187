import {
	OnlinePaymentRequest,
	AdditionalDetailRedirectRequest,
	AdditionalDetailRequest,
	CreateAccountHolderInfo,
	CreateAccountHolderResponse,
	ExtendOrderPaymentRequest,
	AdyenPaymentState,
	GetPaymentMethods,
	AdditionalAdminDetailRedirectRequest,
	AdditionalAdminDetailRequest,
	DisablePaymentCardRequest,
} from 'common/types/adyen';
import { functions } from 'common/services/firebase';
import {
	SimplePaymentResult,
	OnlineOrderRequest,
	OrderObject,
	DiscountCodeApi,
	TeksoReceiptObject,
	AccessClaims,
	UserApi,
	RegisterShopArgs,
	RefundRequest,
	ShopPublicInfo,
	ShopApi,
} from 'common/types';
import { WebhookInfo, WebhookEvent } from 'common/types/api';
import Stripe from 'stripe';

import { RestrictedFeature } from 'common/features/types';
import { CreateOrUpdateContact } from './types';
import { ShopPricing } from 'common/modules/plans';
import errorHandler from 'common/services/errorHandler';

interface FeaturesUpdate {
	enabledFeatures?: RestrictedFeature[];
	hiddenFeatures?: RestrictedFeature[];
}

export interface CardAuthInput {
	shopId: string;
	paymentData: AdyenPaymentState['data'];
	browserData: { origin: string; href: string };
	primary?: boolean;
}

interface DiscountCodeResponse {
	discountCode: DiscountCodeApi | undefined;
	errorMessage: string;
}

type CallableFunctions = {
	onlineOrderWithPayment: [OnlinePaymentRequest, SimplePaymentResult];
	onlineOrderWithPayment_newOnline: [
		OnlinePaymentRequest,
		{ paymentResult: SimplePaymentResult; order: OrderObject },
	];
	addOnlinePaymentDetails: [
		AdditionalDetailRedirectRequest | AdditionalDetailRequest,
		SimplePaymentResult,
	];
	addOnlinePaymentDetails_newOnline: [
		AdditionalDetailRedirectRequest | AdditionalDetailRequest,
		{ paymentResult: SimplePaymentResult; order: OrderObject },
	];
	setOnlineOrder: [OnlineOrderRequest, 'OK'];
	setOnlineOrder_newOnline: [OnlineOrderRequest, OrderObject];
	getPendingOrderObject: [{ orderId: string; shopId: string }, OrderObject | null];
	updateShopPricing: [{ shopId: string; data: Partial<ShopPricing> }, void];
	updateShopFeatures: [{ shopId: string; data: FeaturesUpdate }, void];
	setupInitialReadOnlyData: [{ shopId: string }, void];
	isLiteShop: [{ shopId: string }, boolean];
	getValidatedDiscountCode: [{ shopId: string; discountCode: string }, DiscountCodeResponse];
	getDoesShopHaveActiveDiscountCodes: [{ shopId: string }, boolean];
	createAccountHolder: [
		{
			shopId: string;
			info: CreateAccountHolderInfo;
		},
		CreateAccountHolderResponse,
	];
	sendTeksoReceipt: [{ receiptObject: TeksoReceiptObject }, 'OK'];
	newOrderNumber: [{ orderId: string; shopId: string }, number];
	extendOrderWithPayment: [
		ExtendOrderPaymentRequest,
		{ order?: OrderObject; result: SimplePaymentResult },
	];
	addExtendPaymentDetails: [
		AdditionalDetailRedirectRequest | AdditionalDetailRequest,
		{ order?: OrderObject; result: SimplePaymentResult },
	];
	addHubspotProperties: [CreateOrUpdateContact, 'OK'];
	addHubspotSession: [{ email: string }, number | null];
	hasFeature: [{ feature: RestrictedFeature | RestrictedFeature[]; shopId: string }, boolean];
	pingWebhook: [{ webhook: Partial<WebhookInfo> }, string | null];
	getWebhookTestPayload: [{ event: WebhookEvent }, object];
	emailOrderConfirmation: [OnlineOrderRequest | { data: OnlineOrderRequest; email: string }, 'OK'];
	validateApplePaySession: [
		{ validationURL: string; env: 'TEST' | 'PRODUCTION'; merchantName: string; hostName: string },
		object,
	];
	getOrderWithCode: [{ orderId: string } | { linkId: string }, OrderObject | null];
	confirmLinkOrder: [{ order: OrderObject; emailData: OnlineOrderRequest }, 'OK'];
	saveOrder: [OrderObject, OrderObject];
	authorisePaymentCard: [CardAuthInput, { result: SimplePaymentResult; transactionId: string }];
	getPaymentMethods: [GetPaymentMethods, { data: null | ICheckout.PaymentMethodsResponse }];
	addAdditionalAuthData: [
		{
			shopId: string;
			paymentData: AdditionalAdminDetailRedirectRequest | AdditionalAdminDetailRequest;
			primary?: boolean;
		},
		{ result: SimplePaymentResult; transactionId: string },
	];
	changePrimaryPaymentCard: [
		{
			shopId: string;
			cardToken: string;
		},
		{
			result: string;
		},
	];
	disablePaymentCard: [DisablePaymentCardRequest, IRecurring.DisableResult];
	getBookedOnlineOrderObject: [{ orderId: string; shopId: string }, null | OrderObject];
	updateOrderObject: [OnlineOrderRequest, 'OK'];
	['users-functions-setUserAccess']: [
		{ shopId: string; userId: string; accessClaims: AccessClaims },
		'OK',
	];
	['users-functions-sendInvitationLink']: [{ email: string }, void];
	['users-functions-sendPasswordResetLink']: [{ email: string }, void];
	['users-functions-deleteUserFromShop']: [{ shopId: string; userId: string }, void];
	['users-functions-inviteUsersToShop']: [
		{ shopId: string; emails: string[]; accessClaims: AccessClaims },
		{ created: UserApi[]; failed: string[] },
	];
	['users-functions-registerShop']: [args: RegisterShopArgs, res: { shopId: string } | null];
	refundTransaction: [
		RefundRequest,
		(
			| IPayments.ModificationResult
			| null
			| Stripe.Response<Stripe.PaymentIntent>
			| Stripe.Response<Stripe.Refund>
		),
	];
};

export type CallableFunction = keyof CallableFunctions;

export type CallableInput<T extends CallableFunction> = CallableFunctions[T][0];

export type CallableOutput<T extends CallableFunction> = CallableFunctions[T][1];

const createFunction = <T extends CallableFunction>(
	name: T,
): ((data: CallableInput<T>) => Promise<CallableOutput<T>>) => {
	const callable = functions.httpsCallable(name);
	return async (data: CallableInput<T>) => {
		try {
			const result = await callable(data);
			return result.data;
		} catch (e) {
			errorHandler.report(e);
			throw e;
		}
	};
};

export const onlineOrderWithPayment = createFunction('onlineOrderWithPayment');
export const onlineOrderWithPayment_newOnline = createFunction('onlineOrderWithPayment_newOnline');

export const addOnlinePaymentDetails = createFunction('addOnlinePaymentDetails');
export const addOnlinePaymentDetails_newOnline = createFunction(
	'addOnlinePaymentDetails_newOnline',
);

export const setOnlineOrder = createFunction('setOnlineOrder');
export const setOnlineOrder_newOnline = createFunction('setOnlineOrder_newOnline');

export const getPendingOrderObject = createFunction('getPendingOrderObject');

export const updateShopPricing = createFunction('updateShopPricing');

export const updateShopFeatures = createFunction('updateShopFeatures');

export const setupInitialReadOnlyData = createFunction('setupInitialReadOnlyData');

export const fetchIsLiteShop = createFunction('isLiteShop');

export const getValidatedDiscountCode = createFunction('getValidatedDiscountCode');

export const getDoesShopHaveActiveDiscountCodes = createFunction(
	'getDoesShopHaveActiveDiscountCodes',
);

export const createAccountHolder = createFunction('createAccountHolder');

export const sendTeksoReceipt = createFunction('sendTeksoReceipt');

export const getNewOrderNumber = createFunction('newOrderNumber');

export const extendOrderWithPayment = createFunction('extendOrderWithPayment');

export const addExtendPaymentDetails = createFunction('addExtendPaymentDetails');
export const addHubspotProperties = createFunction('addHubspotProperties');

export const addHubspotSession = createFunction('addHubspotSession');

export const shopHasFeature = createFunction('hasFeature');

export const pingWebhook = createFunction('pingWebhook');

export const getWebhookTestPayload = createFunction('getWebhookTestPayload');

export const emailOrderConfirmation = createFunction('emailOrderConfirmation');

export const validateApplePaySession = createFunction('validateApplePaySession');

export const getOrderWithCode = createFunction('getOrderWithCode');

export const confirmLinkOrder = createFunction('confirmLinkOrder');

export const saveOrder = createFunction('saveOrder');

export const authorisePaymentCard = createFunction('authorisePaymentCard');

export const getAdyenPaymentMethods = createFunction('getPaymentMethods');

export const addAdditionalAuthData = createFunction('addAdditionalAuthData');

export const changePrimaryPaymentCard = createFunction('changePrimaryPaymentCard');

export const disablePaymentCard = createFunction('disablePaymentCard');

export const getBookedOnlineOrderObject = createFunction('getBookedOnlineOrderObject');

export const updateOrderObject = createFunction('updateOrderObject');

export const setUserAccess = createFunction('users-functions-setUserAccess');

export const sendPasswordResetLink = createFunction('users-functions-sendPasswordResetLink');

export const sendInvitationLink = createFunction('users-functions-sendInvitationLink');

export const deleteUserFromShop = createFunction('users-functions-deleteUserFromShop');

export const inviteUsers = createFunction('users-functions-inviteUsersToShop');

export const registerShop = createFunction('users-functions-registerShop');
