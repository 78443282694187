import firebase from 'firebase/app';
// Firebase App is always required and must be first
// const firebase = require('firebase/app');

// Add additional services you want to use
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
// import 'firebase/messaging';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/analytics';
import { browserSupportsIndexedDB, runningJestTests } from 'common/utils/browserUtils';
import { FirebasePerformanceMock } from '../MockFactory/mocks/FirebasePerformance.mock';
import { FirebaseAnalyticsMock } from '../MockFactory/mocks/FirebaseAnalyticsMock.mock';
import FirebaseBatch from './firebaseBatch';
import errorHandler from './errorHandler';

const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: `${firebaseProjectId}.firebaseapp.com`,
	databaseURL: `https://${firebaseProjectId}.firebaseio.com`,
	projectId: firebaseProjectId,
	storageBucket: `${firebaseProjectId}.appspot.com`,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const env = process.env.REACT_APP_ENV as 'local' | 'development' | 'production';

const useEmulator = process.env.REACT_APP_USE_EMULATOR === 'true';

const firebaseApp = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
export const db = firebaseApp.firestore();
db.settings({ ignoreUndefinedProperties: true });

export const functions = firebaseApp.functions('europe-west1');
if (useEmulator /*|| env === 'local'*/) {
	functions.useEmulator('localhost', 5001);
}

export const auth = firebaseApp.auth();

if (useEmulator) {
	db.useEmulator('localhost', 8100);
	//auth.useEmulator('http://localhost:9099/'); //Comment out incase auth is emulated also
}

export const firestore = firebase.firestore;
export const storage = firebaseApp.storage();
export type Timestamp = firebase.firestore.Timestamp;

// Initialize Performance Monitoring and get a reference to the service
export let perf: firebase.performance.Performance =
	browserSupportsIndexedDB && !runningJestTests()
		? firebaseApp.performance()
		: FirebasePerformanceMock;

export let analytics: firebase.analytics.Analytics = FirebaseAnalyticsMock(firebaseApp);

const setAnalyticsIfSupported = async () => {
	try {
		const analyticsSupported = await firebase.analytics.isSupported();
		if (analyticsSupported) {
			analytics = firebaseApp.analytics();
		}
	} catch (e) {
		errorHandler.report(e);
	}
};

const setPersistenceIfSupported = async () => {
	// Test offline persistence first in DEV environment
	if (browserSupportsIndexedDB && env !== 'production' && !runningJestTests() && !useEmulator) {
		try {
			await db.enablePersistence({ synchronizeTabs: true });
		} catch (e) {
			// Cannot initialize offline persistence
			errorHandler.report(e);
		}
	}
};

setAnalyticsIfSupported();
/**
 * Do not use Firestore persistence, does not bring value to us currently,
 * local queries are too slow
 * */
// setPersistenceIfSupported();

export const Batch = FirebaseBatch;

export type WriteBatch = firebase.firestore.WriteBatch;
export const FieldValue = firebase.firestore.FieldValue;

export default firebaseApp;
